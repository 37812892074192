import { createApp } from 'vue'
import configPlugin from './configPlugin';
import { createRouter, createWebHistory } from "vue-router";
import { defineAsyncComponent } from "vue";
import { createI18n } from "vue-i18n";
import "@fortawesome/fontawesome-free/css/all.min.css";

import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import it from "./locales/it.json";
import de from "./locales/de.json";
import nl from "./locales/nl.json";
import pt from "./locales/pt.json";
import ru from "./locales/ru.json";
import zhCN from "./locales/zh-cn.json";
import zhTW from "./locales/zh-tw.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import id from "./locales/id.json";
import hi from "./locales/hi.json";
import th from "./locales/th.json";

let lang;
if(!localStorage.getItem("lang")) {
    lang = 'en';
    localStorage.setItem('lang', 'en')
} else {
    lang = localStorage.getItem("lang")
}

const i18n = createI18n({
    locale: lang,
    fallbackLocale: 'en',
    messages: { en, es, fr, it, de, nl, pt, ru, zhCN, zhTW, ja, ko, id, hi, th }
});

const loadComponent = (component) => defineAsyncComponent(() => import(`./${component}.vue`));
const App = loadComponent("App");

const routes = [
    { path: "/", component: () => import("./AppHome.vue") },
    { path: "/:zoneId", component: () => import("./AppHome.vue"), props: true },
    { path: "/chat", component: () => import("./AppCamouflage.vue"), props: true },
];

// Create Vue Router instance
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.afterEach(() => {
    window.scrollTo(0, 0);
});

/*function isPWA() {
    return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
}*/

router.beforeEach( async (to, from, next) => {
    // MAINTENANCE
    if (process.env.VUE_APP_MAINTENANCE === "1") {
        next("/shadow");
        return;
    }

    next();
});

const app = createApp(App);
app.use(configPlugin);
app.use(router);
app.use(i18n);
app.mount("#app");