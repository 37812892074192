const CAMO = process.env.VUE_APP_CAMO;
const production = process.env.VUE_APP_P === "1";

const WS_BASE = production
    ? process.env.VUE_APP_WSP
    : process.env.VUE_APP_WSD;

const WS_PORT = production
    ? process.env.VUE_APP_WSPP
    : process.env.VUE_APP_WSPD;

const WS_PROTOCOL = production
    ? 'wss'
    : 'ws';

const AX_PROTOCOL = production
    ? 'https'
    : 'http';

// Function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// Check if UUID is stored in local storage, generate and store if not
const UUID = localStorage.getItem('UUID') || generateUUID();
localStorage.setItem('UUID', UUID);

// Function to get user device information
function getUserDeviceInfo() {
  const userAgent = navigator.userAgent.toLowerCase();
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const mobileKeywords = ['android', 'iphone', 'windows phone'];
  const tabletKeywords = ['ipad', 'android'];

  const hasKeyword = (keywords) => keywords.some(keyword => userAgent.includes(keyword));

  if (hasKeyword(mobileKeywords) || (screenWidth < 768 && !hasKeyword(tabletKeywords))) {
    return {
      type: 'Mobile',
      details: `Width: ${screenWidth}, Height: ${screenHeight}`,
    };
  } else if (hasKeyword(tabletKeywords) || (screenWidth >= 768 && screenWidth < 1024)) {
    return {
      type: 'Tablet',
      details: `Width: ${screenWidth}, Height: ${screenHeight}`
    };
  } else {
    return {
      type: 'Desktop',
      details: `Width: ${screenWidth}, Height: ${screenHeight}`
    };
  }
}

// Get user device information from local storage or generate and store it
let DEVICE = JSON.parse(localStorage.getItem('DEVICE'));
if (!DEVICE) {
  DEVICE = getUserDeviceInfo();
  localStorage.setItem('DEVICE', JSON.stringify(DEVICE));
}

if(!localStorage.getItem('merlin-tables')) {
  localStorage.setItem('merlin-tables', '[]');
}

if(!localStorage.getItem('guardian-friends')) {
  localStorage.setItem('guardian-friends', '[]');
}

if(!localStorage.getItem('notifications')) {
  localStorage.setItem('notifications', true);
}

if(!localStorage.getItem('invitations')) {
  localStorage.setItem('invitations', true);
}

export default {
  CAMO,
  WS_BASE,
  WS_PORT,
  WS_PROTOCOL,
  AX_PROTOCOL,
  UUID,
  DEVICE,
};